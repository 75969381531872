import { merge } from "lodash";
import { IVariaveisAmbiente, IAmbienteUnico } from "./types";
import { EnumDeviceType } from "../model/enums/enum-device-type";

const AmbienteEnviado = (process.env.REACT_APP_ENV || "mpProd") as keyof IVariaveisAmbiente;
const AmbientePadrao: keyof IVariaveisAmbiente = AmbienteEnviado;
const LabelEnviado = (process.env.REACT_APP_LABEL || "Touchone") as keyof IVariaveisAmbiente;

// const apiUrl = 'https://api-gateway.touchone.com.br';


export const getEnvironment = (): `prodWeb` | `devWeb` => {
      const environment = (process.env.REACT_APP_ENV || "prodWeb") as `prodWeb` | `devWeb`;
      return environment
  }

const enviroment = getEnvironment()

const ambiente = enviroment === 'prodWeb' ? 'prod' : 'staging'

const apiUrl = `https://api-gateway-${ambiente}.touchone.com.br`
const apiSetoresUrl = `https://api-setores-${ambiente}.touchone.com.br`
const apiPedidosUrl = `https://api-pedidos-${ambiente}.touchone.com.br`

const gatewayVersion = 'v3';
const setoresVersion = 'v5';
const pedidosVersion = 'v2';

// variáveis de ambiente(óbvio)

const _variaveisAmbiente: IVariaveisAmbiente = {
  all: {
    version: "1.0.5",
    isDev: (["prodWeb"].find(x => x === AmbientePadrao.toString())?.length || 0) === 0,
    environment: AmbienteEnviado,
    googleAppId: "27279191635-5kdincvmbm92lihatr0k4tm1o4kon8ue.apps.googleusercontent.com",
    saurusUtilsWebService: "https://wsmaster.saurus.net.br/v003/servicemaster.asmx",
    label: LabelEnviado,
  },
  devWeb: {
    apiUrl: apiUrl,
    paymentDevice: EnumDeviceType.NAVIGATOR,
    apiSetoresUrl: apiSetoresUrl,
    apiPedidosUrl: apiPedidosUrl,
    facebookAppId: "661218711269424",
    frontUrl: "https://app.touchone.com.br",
    gatewayVersion: gatewayVersion,
    pedidosVersion: pedidosVersion,
    setoresVersion: setoresVersion,
  },
  prodWeb: {
    apiUrl: apiUrl,
    paymentDevice: EnumDeviceType.NAVIGATOR,
    apiSetoresUrl: apiSetoresUrl,
    apiPedidosUrl: apiPedidosUrl,
    facebookAppId: "1880946222051906",
    frontUrl: "https://app.touchone.com.br",
    gatewayVersion: gatewayVersion,
    pedidosVersion: pedidosVersion,
    setoresVersion: setoresVersion,
  },
};

const _ambienteUnico =
  (_variaveisAmbiente[AmbientePadrao] as IAmbienteUnico) || _variaveisAmbiente.devWeb;
export const VariaveisAmbiente = merge(_variaveisAmbiente.all, _ambienteUnico);
